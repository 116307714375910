import React from "react"
import { Link } from "gatsby"
import "./videoTestimonial.scss"

const VideoTestimonial = () => {
  return (
    <div className="section-container demo-day-section">
      <div className="container">
        <div className="row">
          <div className="col-md-8 order-lg-4">
            <div className="embed-responsive embed-responsive-16by9">
              <iframe
                className="embed-responsive-item"
                title="Awal success story"
                src="https://www.youtube.com/embed/aNs8OaVkOMg"
                frameBorder="0"
                allow="autoplay; encrypted-media"
                allowFullScreen
              ></iframe>
            </div>
          </div>
          <div className="col-md-4">
            <div className="row text-center">
              <div className="col-lg-12">
                <h3 className="main-section-heading text-center">
                  Earn back your tuition
                </h3>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <p>
                  The high demand for professionals in technology makes it one
                  of the best-paying industries globally.
                </p>

                <p>
                  After just 5 years as a fellow at codetrain, Awal transitioned
                  into working remotely for a Canadian gaming company. Less than
                  1 year after he joined Big Viking games, Awal is now a senior
                  developer. He works right here from his home in Ghana or
                  anywhere else he finds himself.
                </p>
              </div>
            </div>
            <div className="row">
              <Link
                to="https://app.codetrain.africa/apply"
                className="btn-custom-primary-large enroll-section mx-auto"
              >
                Enroll now
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default VideoTestimonial
