import React from "react"
import "./aboutCodetrain.scss"
import { Link } from "gatsby"

const AboutCodetrain = () => {
  return (
    <div className="about-codetrain section-container">
      <div className="container">
        <div className="row">
          <div className="col-md-10 offset-md-1 col-sm-12 col-xs-12">
            <div className="about-text text-center">
              <h5>
                As the demand for software developers begins to outgrow supply,
                our mission is to empower africans to take up space on the
                global tech scene.
              </h5>
              {/* <p>
                Our mission is to empower Africans to take up space on the
                global tech scene.
              </p> */}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xs-12 col-md-4">
            <div className="option-box option-box1">
              <h1>89%</h1>
              <p>of our students get jobs within 9 months</p>
            </div>
          </div>
          <div className="col-xs-12 col-md-4">
            <div className="option-box option-box2">
              <h1>600</h1>
              <p>alumni and counting</p>
            </div>
          </div>
          <div className="col-xs-12 col-md-4">
            <div className="option-box option-box3">
              <h1>8.3</h1>
              <p>students' rating of our program on a 0-10 scale</p>
            </div>
          </div>
        </div>

        {/* <div className="row enroll-section">
          <Link
            to="/courses"
            className="btn-outline-custom-secondary-normal mx-auto"
          >
            Learn more about our course
          </Link>
        </div> */}
      </div>
    </div>
  )
}

{
  /* <div className="col-md-10 offset-md-1 col-sm-12 col-xs-12">
            <div className="main-section-heading">
              <h3 className="text-center">What is Codetrain</h3>
            </div>
            <div className="row about-codetrain_row">
              <div className="col-md-4 col-xs-12">
                <img src={anita} alt="Codetrain Baobab" className="img-fluid" />
              </div>
              <div className="col">
                <div className="about-codetrain_text">
                  <h5 className="custom-blue-text">
                    Our Training Model - The Coding Bootcamp
                  </h5>
                  <p>
                    The high demand for software developers has inspired a new
                    form of education, the Coding Bootcamp.
                  </p>
                  <p>
                    Codetrain's coding bootcamp program is based on this
                    innovative way of education designed to provide aspiring
                    software developers with the technical skills they require
                    to start their careers in the fast-paced tech industry.
                  </p>
                  <p>
                    The Codetrain Coding Bootcamp offers{" "}
                    <ul>
                      <li>immersive and project based training</li>
                      <li>
                        job ready skills in full-stack web and mobile
                        development
                      </li>
                      <li>
                        mentoring from successful software developers from
                        globally successful companies like Uber, Spotify,
                        Microsoft, Facebook, Netflix, Apple etc.
                      </li>
                      <li>connection to employers</li>
                    </ul>
                  </p>
                  <br />
                  <h5 className="custom-blue-text">
                    Our Schedules are Flexible
                  </h5>
                  <div className="row">
                    <div className="col-xs-12 col-md-4">
                      <div className="option-box option-box1">
                        <p>Weekday option</p>
                      </div>
                    </div>
                    <div className="col-xs-12 col-md-4">
                      <div className="option-box option-box2">
                        <p>Weekend option</p>
                      </div>
                    </div>
                    <div className="col-xs-12 col-md-4">
                      <div className="option-box option-box3">
                        <p>Virtual class option</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */
}

export default AboutCodetrain
