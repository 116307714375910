import React from "react"
import { Link } from "gatsby"
import hannah from "../../assets/img/people/hannah.jpg"
import razak from "../../assets/img/people/razak.jpg"
import doreen from "../../assets/img/people/doreen.jpg"
import zak from "../../assets/img/people/zak.jpg"
import "./homeTestimonials.scss"

const HomeTestimonials = () => {
  return (
    <div className="section-container home-testimonials-section">
      <div className="container">
        <div className="main-section-heading">
          <h3 className="text-center">Success stories</h3>
        </div>
        <div className="row home-testimonials-section__text">
          <div className="col-xs-12 col-sm-12 col-lg-6">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-lg-6">
                <blockquote>
                  <p>
                    The Codetrain training experience gave me the essential soft
                    skills required to be successful in international work
                    environments. I now work for a company based in Berlin as a
                    Front-end Engineer.
                  </p>
                  <footer className="blockquote-footer">
                    Zak -
                    <cite title="Source Title">
                      Web Developer, Infinitas Learning
                    </cite>
                  </footer>
                </blockquote>
              </div>
              <div className="col-xs-12 col-sm-12 col-lg-6">
                <img
                  src={zak}
                  alt="Razak"
                  className="img-fluid home-testimonials-section__text--img"
                />
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-lg-6">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-lg-6">
                <blockquote>
                  <p>
                    I joined Codetrain after High School. After completing the
                    one year training program, I got a job as a mobile
                    developer.
                  </p>
                  <footer className="blockquote-footer">
                    Doreen -{" "}
                    <cite title="Source Title">
                      Mobile Developer, Amatsii Ltd
                    </cite>
                  </footer>
                </blockquote>
              </div>
              <div className="col-xs-12 col-sm-12 col-lg-6">
                <img
                  src={doreen}
                  alt="Afia"
                  className="img-fluid home-testimonials-section__text--img"
                />
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-lg-6">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-lg-6">
                <blockquote>
                  <p>
                    I excelled quickly at Codetrain to become one of the best
                    Frontend Developers. I built several projects including an
                    app for Nanny agencies.
                  </p>
                  <footer className="blockquote-footer">
                    Hannah -
                    <cite title="Source Title">
                      Frontend Developer, BlueSPACE Africa
                    </cite>
                  </footer>
                </blockquote>
              </div>
              <div className="col-xs-12 col-sm-12 col-lg-6">
                <img
                  src={hannah}
                  alt="Makafui"
                  className="img-fluid home-testimonials-section__text--img"
                />
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-lg-6">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-lg-6">
                <blockquote>
                  <p>
                    I got fully employed as a web developer after completing
                    Codetrain's practical training program in coding.
                  </p>
                  <footer className="blockquote-footer">
                    Abdul-Razak -
                    <cite title="Source Title">Web Developer, AgroCenta</cite>
                  </footer>
                </blockquote>
              </div>
              <div className="col-xs-12 col-sm-12 col-lg-6">
                <img
                  src={razak}
                  alt="Zak"
                  className="img-fluid home-testimonials-section__text--img"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <Link
            to="/success_stories"
            className="btn-outline-custom-secondary-normal enroll-section mx-auto"
          >
            See more success stories
          </Link>
        </div>
      </div>
    </div>
  )
}

export default HomeTestimonials
