import React from "react"
import "./coursesUiUx.scss"
import { Link } from "gatsby"
const CoursesUiUx = () => {
  return (
    <div className="section-container plans-intro">
      <div className="container">
        <div className="main-section-heading">
          <h3 className="text-center">Our Courses</h3>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-12">
            <div className="cards">
              <div className="card-header card-header__firsts">
                <h5>Software Engineering</h5>
              </div>
              <div class="card-body">
                <p class="card-text">
                  This is a 2 year program. By the end, you will go from novice
                  to a mid-level software developer. The first year has a focus
                  on training in software development. While the second year
                  narrows down on career services that are aimed at help- ing
                  you launch your career in the best way. It consists of
                  interview prep, technical interview workshops, an innovation
                  challenge, and internships.
                </p>
              </div>
              <Link
                to="/courses"
                className="btn-outline-custom-secondary-normal mx-auto"
              >
                Learn more about Software Engineering
              </Link>
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
            <div className="cards">
              <div className="card-header card-header__second">
                <h5>UX/UI Design</h5>
              </div>
              <div class="card-body">
                <p class="card-text">
                  This is a 1 year program. By the end, you will be transformed
                  into a a professional capable of evaluating product
                  requirements, illustrate design ideas, and and then use your
                  newly acquired skills to bring it to life in the form of
                  websites and mobile apps. The course includes collaboration
                  with software teams and internship opportunities
                </p>
              </div>
              <Link
                to="/ux-ui-design"
                className="btn-outline-custom-secondary-normal mx-auto"
              >
                Learn more about UX/UI design
              </Link>
            </div>
          </div>
        </div>

        <div className="row enroll-section"></div>
      </div>
    </div>
  )
}

export default CoursesUiUx
