import React from "react"
import "./employmentProgram.scss"

const EmploymentProgram = () => {
  return (
    <div className="about-codetrain section-container">
      <div className="container">
        <div className="row">
          <div className="col-md-10 offset-md-1 col-sm-12 col-xs-12">
            <div className="main-section-heading">
              <h3 className="text-center">
                The Codetrain education to employment program
              </h3>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col text-center">
            <p>
              Our two-year program will take you from novice to mid-level
              software developer. By the end of your training, you will be
              familiar with all the technologies and concepts that the world’s
              best software engineers use in their work. You will also get the
              opportunity to be connected to recruiters from local and
              international companies.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EmploymentProgram
