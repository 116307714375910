import React from "react"
import "./codetrainMedia.scss"

import ventureburn from "../../assets/img/logos/ventureburn.png"
import enpact from "../../assets/img/logos/enpact-logo.png"
import techpoint from "../../assets/img/logos/Techpoint.africa-Logo.png"
import disrupt from "../../assets/img/logos/DA-logo.png"
import mest from "../../assets/img/logos/mest.jpg"
import ghanaweb from "../../assets/img/logos/ghanaweb.png"
import kuulpeeps from "../../assets/img/logos/kuupeeps.png"
import bftOnline from "../../assets/img/logos/BFT-Online-logo.png"
import ghHeadlines from "../../assets/img/logos/ghheadlines.png"
import gna from "../../assets/img/logos/gna-logo-blue-icon.png"
import accra from "../../assets/img/logos/acccc1.jpg"
import yceo from "../../assets/img/logos/yceo.png"
import soju from "../../assets/img/logos/soju.jpg"
import spiritedHub from "../../assets/img/logos/spirtedhub.jpg"
import tv3 from "../../assets/img/logos/tv3.png"
import joyfm from "../../assets/img/logos/joyfm.jpg"
// import kuulpeeps from "../../assets/img/logos/kuupeeps.png";

const CodetrainMedia = () => {
  return (
    <div className="section-container codetrain-media-section">
      <div className="container">
        <div className="main-section-heading">
          <h3 className="text-center">Codetrain in the media</h3>
        </div>
        <div className="row codetrain-media-section__content">
          <div className="d-flex flex-wrap justify-content-center">
            <a
              href="https://ventureburn.com/2019/08/seedstars-accra-pitch-competition"
              rel="nofollow noopener noreferrer"
              target="_blank"
            >
              <img src={ventureburn} alt="Ventureburn" className="media-logo" />
            </a>
            <a
              href="http://www.enpact.org/creating-the-future-tech-talent/"
              rel="nofollow noopener noreferrer"
              target="_blank"
            >
              <img src={enpact} alt="Enpact" className="media-logo" />
            </a>
            <a
              href="https://techpoint.africa/2019/01/15/codetrain-feature/?fbclid=IwAR2_WZHXUYxOi53OcHhuUseLvK1Tv_u4Oc9_3_Gt-SC-l2pftBcoJ2lrY3Q"
              rel="nofollow noopener noreferrer"
              target="_blank"
            >
              <img
                src={techpoint}
                alt="Techpoint Africa"
                className="media-logo"
              />
            </a>
            <a
              href="http://disrupt-africa.com/2019/08/meet-ghanas-codetrain-the-latest-addition-to-africas-cohort-of-coding-schools/?fbclid=IwAR2CdVeVhk3O5Vf3VtlI294q148lLyJ9lE-UMJHFg8WIfz_DFsVqKmrsMdQ"
              rel="nofollow noopener noreferrer"
              target="_blank"
            >
              <img src={disrupt} alt="Disrupt Africa" className="media-logo" />
            </a>
            <a
              href="http://meltwater.org/mest-alum-richard-briandt-trains-future-developers-through-codetrain/"
              rel="nofollow noopener noreferrer"
              target="_blank"
            >
              <img src={mest} alt="MEST" className="media-logo" />
            </a>
            <a
              href="https://www.ghanaweb.com/GhanaHomePage/business/2018-Codetrain-conference-held-at-KNUST-702405"
              rel="nofollow noopener noreferrer"
              target="_blank"
            >
              <img src={ghanaweb} alt="Ghana Web" className="media-logo" />
            </a>
            <a
              href="https://kuulpeeps.com/2019/06/founder-of-codetrain-richard-brandt-wins-ict-entrepreneur-of-the-year-award-at-the-2019-young-entrepreneurs-international-awards/"
              rel="nofollow noopener noreferrer"
              target="_blank"
            >
              <img src={kuulpeeps} alt="Kuul Peeps" className="media-logo" />
            </a>
            <a
              href="https://thebftonline.com/2019/lifestylesports/codetrain-ceo-wins-ict-entrepreneur-of-the-year-award-at-the-yeis2019-awards/?fbclid=IwAR0y1jcuj9UkeBsdclYxspg5CbriXfBo1ji8CN7TZUdvja08ChE4Lop6uic"
              rel="nofollow noopener noreferrer"
              target="_blank"
            >
              <img src={bftOnline} alt="BFT Online" className="media-logo" />
            </a>
            <a
              href="http://ghheadlines.com/agency/ghana-news-agency/20170727/50109211/codetrain-launched-to-train-app-developers"
              rel="nofollow noopener noreferrer"
              target="_blank"
            >
              <img
                src={ghHeadlines}
                alt="GH Headlines"
                className="media-logo"
              />
            </a>
            <a
              href="http://www.ghananewsagency.org/science/codetrain-launched-to-train-app-developers-120162"
              rel="nofollow noopener noreferrer"
              target="_blank"
            >
              <img src={gna} alt="GNA" className="media-logo" />
            </a>
            <a
              href="https://accraconnect.net/codetrain-app-developer-conference-kumasi/
							?fbclid=IwAR3M7U80sd0gPVmxU92z00Anl2hecBy_JjV1nM3QMTNOM6HmHVbFfQf7hs4"
              rel="nofollow noopener noreferrer"
              target="_blank"
            >
              <img src={accra} alt="Accra Connect" className="media-logo" />
            </a>
            <a
              href="https://www.theyceo.com/2019/06/the-yceo-codetrain-ceo-wins-ict.html?fbclid=IwAR2l4Za7Ycoh3pvTTQZkWWgBXXR4q9QIjemZobk1x8qzXr5E7xOAOxJ-bQ0"
              rel="nofollow noopener noreferrer"
              target="_blank"
            >
              <img src={yceo} alt="YCEO" className="media-logo" />
            </a>
            <a
              href="https://www.youtube.com/watch?v=1WQGAFEusaA&feature=youtu.be"
              rel="nofollow noopener noreferrer"
              target="_blank"
            >
              <img src={soju} alt="Soju" className="media-logo" />
            </a>
            <a
              href="http://thespiritedhub.com/codetrain-train-1000-app-developers/
							?fbclid=IwAR21PnJAxmLbE212ujHuLddj7hTwaa8X4jf30GdeKUhlo2rA42w3_LK9Jwk"
              rel="nofollow noopener noreferrer"
              target="_blank"
            >
              <img
                src={spiritedHub}
                alt="Spirited Hub"
                className="media-logo"
              />
            </a>
            <img src={tv3} alt="TV3" className="media-logo" />
            <img src={joyfm} alt="Joy Fm" className="media-logo" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default CodetrainMedia
