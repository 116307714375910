import React from "react"
import "./homeArticles.scss"

import codetrainteam from "../../assets/img/full-photos/codetrain-team.png"
import yceo from "../../assets/img/full-photos/richard-brandt-top-50.jpeg"
import EboWhyte from "../../assets/img/full-photos/EboWhyte.jpeg"
import Panel from "../../assets/img/full-photos/Panel.webp"

const HomeArticles = () => {
  return (
    <div className="section-container home-articles-section">
      <div className="container">
        <div className="main-section-heading">
          <h3 className="text-center">The exciting stories about us</h3>
        </div>
        <div className="row home-articles-section__content">
          <div className="card-deck">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6 col-sm-6">
                    <img
                      src={codetrainteam}
                      className="img-fluid article-img"
                      alt="Codetrain CEO wins in ICT"
                    />
                  </div>
                  <div className="col-md-6 col-sm-6">
                    <div className="card-block">
                      <h5 className="card-title custom-blue-text">
                        Codetrain becomes the first company to join I&P’s
                        education to employment program.
                      </h5>
                      <p className="card-text">
                        The partner companies receive funding and technical
                        assistance to support them in their digital
                        transformation...
                        <a
                          href="https://www.ietp.com/en/content/ghana-two-edtech-companies-join-ip-education-employment-program"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          read more
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6 col-sm-6">
                    <img
                      src={yceo}
                      className="img-fluid article-img"
                      alt="Codetrain's Richard Brandt is top 50 CEOs"
                    />
                  </div>
                  <div className="col-md-6 col-sm-6">
                    <div className="card-block">
                      <h5 className="card-title custom-blue-text">
                        Stonebwoy, Richard Brandt, DBlack, and others make 2019
                        Top 50 Young CEOs in Ghana
                      </h5>
                      <p className="card-text">
                        TheYCEO & Avance Media have officially released the 2019
                        list of the Top 50 Young CEOs in Ghana, an annual
                        ranking which celebrates...
                        <a
                          href="https://medium.com/@codetrain/stonebwoy-richard-brandt-dblack-and-others-make-2019-top-50-young-ceos-in-ghana-457b24e36b16"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          read more
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row home-articles-section__content">
          <div className="card-deck">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6 col-sm-6">
                    <img
                      src={EboWhyte}
                      className="img-fluid article-img"
                      alt="Paul"
                    />
                  </div>
                  <div className="col-md-6 col-sm-6">
                    <div className="card-block">
                      <h5 className="card-title custom-blue-text">
                        “Be courageous, enthusiastic” — Ebo Whyte inspires young
                        software developers
                      </h5>
                      <p className="card-text">
                        Renowned Ghanaian playwright and author, Mr James Ebo
                        Whyte, has advised newly trained software developers to
                        exhibit...
                        <a
                          href="https://citinewsroom.com/2023/10/be-courageous-enthusiastic-ebo-whyte-inspires-young-software-developers/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          read more
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6 col-sm-6">
                    <img
                      src={Panel}
                      className="img-fluid article-img"
                      alt="Paul"
                    />
                  </div>
                  <div className="col-md-6 col-sm-6">
                    <div className="card-block">
                      <h5 className="card-title custom-blue-text">
                        Codetrain Tech Seminar: Technology companies urged to
                        provide attractive packages to retain employees
                      </h5>
                      <p className="card-text">
                        Accra, Oct. 16, GNA – Founders and owners of technology
                        companies should provide attractive packages ...
                        <a
                          href="https://gna.org.gh/2023/10/codetrain-tech-seminar-technology-companies-urged-to-provide-attractive-packages-to-retain-employees/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          read more
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomeArticles
