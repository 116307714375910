import React from "react"
import { Title, Meta } from "react-head"
import MainLayout from "../components/MainLayout"
import HomeHero from "../components/home-hero/HomeHero"
import HomeTestimonials from "../components/home-testimonials/HomeTestimonials"
import Contact from "../components/contact-us/Contact"
import HomeArticles from "../components/home-articles/HomeArticles"
import CodetrainMedia from "../components/codetrain-media/CodetrainMedia"
import AboutCodetrain from "../components/about-codetrain/AboutCodetrain"

import "../assets/css/bootstrap.min.css"
import "../assets/css/main.scss"
import VideoTestimonial from "../components/home-video-testimonial/VideoTestimonial"
import WhyDifferent from "../components/why-different/WhyDifferent"
import FrequentlyAskedQuestions from "../components/faq/FrequentlyAskedQuestions"
import FeesContent from "../components/fees/FeesContent"
import HowToApply from "../components/how-to-apply/HowToApply"
import EmploymentProgram from "../components/employment-program/EmploymentProgram"
import IsaPilot from "../components/isa-pilot/IsaPilot"
import CoursesUiUx from "../components/courses-ui-ux/CoursesUiUx"

export default () => {
  return (
    <MainLayout>
      <Title>Codetrain Africa | Apps coding bootcamp</Title>
      <Meta
        name="description"
        content="Learn fullstack web and mobile apps development from the most holistic coding bootcamp. We have live interactive virtual and in-person options available."
      />
      <HomeHero />
      <AboutCodetrain />
      <CoursesUiUx />
      <VideoTestimonial />
      <EmploymentProgram />
      <HomeTestimonials />
      <WhyDifferent />
      <HowToApply />
      <FeesContent />
      <IsaPilot />
      <FrequentlyAskedQuestions />
      <Contact />
      <HomeArticles />
      <CodetrainMedia />
    </MainLayout>
  )
}
