import React from "react"

import "./FrequentlyAskedQuestions.scss"

const FrequentlyAskedQuestions = () => {
  return (
    <div className="section-container frequently-asked-section">
      <div className="container">
        <div className="row">
          <div className="col-lg-10 offset-lg-1">
            <div className="main-section-heading">
              <h3 className="text-center">
                Frequently asked questions (Software engineering)
              </h3>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <ul>
                  <li>
                    <h5 className="duration-text">
                      What is the duration of the course?
                    </h5>
                    <p>The duration of the course is 24 months</p>
                    <p>
                      The first year is when most of the training happens.
                      Candidates learn how to code from scratch and begin to
                      build their portfolios right from month one.
                    </p>
                    <p>
                      The second year focuses on career services. Trainees have
                      internships, mock interviews, and a mega Demo Day to
                      prepare them to officially graduate and land their first
                      full-time roles.
                    </p>
                  </li>

                  <li>
                    <h5 className="schedule-text">
                      What is the schedule for the class?
                    </h5>
                    <p>
                      You get access to course content and are expected to study
                      it. You will receive instructions on what exercises to do.
                      Once every week, there is a live class to go over the
                      course content that was made available. You get to choose
                      when your live class happens. Here are the options:
                    </p>
                    <p>
                      <ul>
                        <li>Weekday live class</li>
                        <li>Weekend live class</li>
                        <li>Virtual live class</li>
                      </ul>
                    </p>
                  </li>

                  <li>
                    <h5 className="study-hours-text">
                      How many hours do I need to study per week?
                    </h5>
                    <p>
                      You should be able to commit about 24 hours a week. (about
                      a full day).
                    </p>
                  </li>

                  <li>
                    <h5 className="class-start-text">
                      Does Codetrain provide laptops?
                    </h5>
                    <p>No, we currently don’t provide laptops.</p>
                  </li>
                </ul>
              </div>

              <div className="col-lg-6">
                <ul>
                  <li>
                    <h5 className="requirement-text">
                      What are the requirements to begin the course?
                    </h5>

                    <p>What you don't need</p>
                    <ul>
                      <li>Computer science degree</li>
                      <li>Experience in tech</li>
                      <li>A degree</li>
                    </ul>

                    <p>But you do need to:</p>
                    <p>
                      <ul>
                        <li>Be computer literate</li>
                        <li>Be teachable</li>
                        <li>Be willing to put in the work</li>
                        <li>Pass our intake assessment</li>
                      </ul>
                    </p>
                  </li>

                  <li>
                    <h5 className="scholarship-text">
                      Do you offer any scholarships?
                    </h5>
                    <p>
                      Yes, we offer part scholarships to candidates who need
                      them and apply for them.
                    </p>
                  </li>

                  <li>
                    <h5 className="it-background-text">
                      Do you require an IT or computer science background for
                      Codetrain?
                    </h5>
                    <p>
                      No, people from diverse backgrounds of study can enroll
                      and be successful.
                    </p>
                  </li>

                  <li>
                    <h5 className="class-start-text">When do classes start?</h5>
                    <p>January, May, and October.</p>
                  </li>

                  <li>
                    <h5 className="class-start-text">
                      What certificate do I get after completing the program?
                    </h5>
                    <p>
                      You get a coding bootcamp certificate as well as a
                      portfolio full of projects you could use to apply for
                      jobs.
                    </p>
                  </li>
                </ul>
              </div>

              {/* <div className="col">
                <ul>
                  <li>
                    <div className="row">
                      <div className="col-lg-6 col-sm-12">
                        <h5>
                          What's the difference between the old and new
                          programs?
                        </h5>
                      </div>
                    </div>
                    <table class="table table-striped">
                      <thead>
                        <tr>
                          <th scope="col">Codetrain 2.0</th>
                          <th scope="col">Codetrain 3.0</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>no specialization</td>
                          <td>specialized tracks available</td>
                        </tr>
                        <tr>
                          <td>
                            informal, unstructured career services program
                          </td>
                          <td>formal, structured career services program</td>
                        </tr>
                        <tr>
                          <td>lax post program fellowship</td>
                          <td>robust post program fellowship</td>
                        </tr>
                        <tr>
                          <td>trainees graduate as junior-level developers</td>
                          <td>trainees graduate as mid-level developers</td>
                        </tr>

                        <tr>
                          <td>technical focused program</td>
                          <td>
                            balanced program that integrates career readiness
                            training and essential skills.
                          </td>
                        </tr>
                        <tr>
                          <td>program developed in-house</td>
                          <td>
                            program developed with FAANG engineers and talent
                            professionals from partner companies
                          </td>
                        </tr>
                        <tr>
                          <td>1 year</td>
                          <td>2 years</td>
                        </tr>
                        <tr>
                          <td>
                            accessible to trainees who can afford to pay full
                            fees only
                          </td>
                          <td>
                            accessible to trainees from diverse backgrounds due
                            to diversity, equity and inclusion program
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </li>
                </ul>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FrequentlyAskedQuestions
