import React from "react"
import { Link } from "gatsby"
import "./feesContent.scss"

const FeesContent = () => {
  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-lg-10 offset-lg-1">
            <div className="main-section-heading">
              <h3 className="text-center">Our fees</h3>
            </div>

            <div className="row">
              <div className="col-md-6 ">
                <div className="card-header card-header__first">
                  <h5>Software Engineering</h5>
                </div>
                <p>
                  Our two year education to employment package costs GH₵38,500
                  for Africans living in Africa and $3000 for other applicants.
                </p>

                <p>
                  We are able to offer flexible payment options and part
                  scholarships for diversity and inclusion.
                </p>
                {/* <div className="row" style={{ marginTop: 40 }}>
                  <p className="fees-btn mx-auto">
                    <Link
                      to="/payment_plan"
                      className="btn-outline-custom-secondary-normal"
                    >
                      Pick a payment plan
                    </Link>
                  </p>
                </div> */}
              </div>
              <div className="col-md-6 ">
                <div className="card-header card-header__fourth ">
                  <h5>UX/UI Design</h5>
                </div>
                <p>
                  Our one year education to employment package costs GH₵19,000
                  for Africans living in Africa and $1900 for other applicants.
                </p>

                <p>
                  We are able to offer flexible payment options and part
                  scholarships to promote diversity and inclusion.
                </p>
                {/* <div className="row" style={{ marginTop: 40 }}>
                  <p className="fees-btn mx-auto ">
                    <Link
                      to="/scholarship"
                      className="btn-outline-custom-secondary-normal"
                    >
                      Apply for a Part Scholarship
                    </Link>
                  </p>
                </div> */}
              </div>
            </div>

            <br />

            <div className="row">
              {" "}
              <div className="col-md-6 col-xs-12 text-center mx-auto">
                <p className="fees-btn">
                  <Link
                    to="/payment_plan"
                    className="btn-outline-custom-secondary-normal"
                  >
                    Pick a Payment Plan
                  </Link>
                </p>
              </div>
              <div className="col-md-6 col-xs-12 text-center mx-auto">
                <p className="fees-btn">
                  <Link
                    to="/scholarship"
                    className="btn-outline-custom-secondary-normal"
                  >
                    Apply for a Part Scholarship
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FeesContent
