import React from "react"
import { Link } from "gatsby"

import "./howToApply.scss"

const HowToApply = () => {
  return (
    <div className="section-container how-to-apply-section">
      <div className="container">
        <div className="row">
          <div className="col-lg-10 offset-lg-1">
            <div className="main-section-heading">
              <h3 className="text-center">Apply in 3 simple steps</h3>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-4">
            <h4 className="stage-number stage-number1">Step 1</h4>
            <div className="option-box option-box1">
              <h5>Fill the Enroll now form</h5>
            </div>
          </div>
          <div className="col-xs-12 col-md-4">
            <h4 className="stage-number stage-number2">Step 2</h4>
            <div className="option-box option-box2">
              <h5>Complete application</h5>
            </div>
          </div>
          <div className="col-xs-12 col-md-4">
            <h4 className="stage-number stage-number3">Step 3</h4>
            <div className="option-box option-box3">
              <h5>Start your codetrain journey</h5>
            </div>
          </div>
        </div>
        <div className="row">
          <Link
            to="https://app.codetrain.africa/apply"
            className="btn-custom-primary-large enroll-section mx-auto"
          >
            Enroll now
          </Link>
        </div>
      </div>
    </div>
  )
}

export default HowToApply
