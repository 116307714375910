import { Link } from "gatsby"
import React from "react"

const IsaPilot = () => {
  return (
    <div className=" mt-5">
      <div className="container">
        <div className="main-section-heading">
          <h3 className="text-center ">
            Codetrain Africa study now pay later program
          </h3>
        </div>
        <div className="row">
          <div className="col">
            <p className="text-center">
              Codetrain is excited to announce its partnership with Chancen
              International, introducing an innovative financial model for
              students. This model empowers individuals without immediate
              financial resources to pursue their studies now and pay later. The
              initiative kicks off with a pilot program, initially supporting 20
              students on their educational journey.
            </p>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-md-6 col-xs-12">
            <ul>
              <li>
                <h6>About Chancen International</h6>
                <p>
                  CHANCEN International is a not-for-profit organization that
                  provides an ethical, fair, and responsible financing model for
                  young people in Ghana and in Africa to access quality tertiary
                  education. This model of financing is called an Income Share
                  Agreement (ISA).
                </p>
              </li>
              <li>
                <h6>What is Income Share Agreement?</h6>
                <p>
                  An Income Share Agreement (ISA) is a fair and ethical way for
                  you to finance your education without creating any burdensome
                  debt. You get the freedom to focus on your studies without any
                  pressure and enter the world of work focusing things that
                  really matter.
                </p>
              </li>
            </ul>
          </div>
          <div className="col-md-6 col-xs-12">
            <ul>
              <li>
                <h6>Benefits of Income share Agreement (ISA)</h6>
                <ul>
                  <li>You study now and pay later.</li>
                  <li>You start paying when you start making an income.</li>
                  <li>
                    You only pay a certain percentage of your net income during
                    repayment.
                  </li>
                  <li>
                    Your repayment is capped so that you are not saddled with
                    debt.
                  </li>
                  <li>
                    Your repayments go towards the fund for the next generation.
                  </li>
                </ul>
              </li>
            </ul>
          </div>{" "}
          <p className="ml-5">Are you interested in applying for this?</p>
        </div>
        <div className="row my-3">
          {" "}
          <div className="col-md-6 col-xs-12 text-center mt-3">
            <p className="fees-btn">
              <Link to="/isa/" className="btn-outline-custom-secondary-normal">
                Learn more about ISA
              </Link>
            </p>
          </div>{" "}
          <div className="col-md-6 col-xs-12 text-center mt-3">
            <Link
              to="https://tally.so/r/wkNerd"
              className="btn-custom-primary-large mx-auto"
            >
              Apply for Study Now, Pay Later
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default IsaPilot
