import React from "react"
import { Link } from "gatsby"
import "./whyDifferent.scss"

const WhyDifferent = () => {
  return (
    <div className="section-container demo-day-section">
      <div className="container">
        <div className="row">
          <div className="col-md-8">
            <div className="embed-responsive embed-responsive-16by9">
              {/* <iframe
                className="embed-responsive-item"
                title="Demo day"
                src="https://www.youtube.com/embed/8ceNPJzXMok"
                frameBorder="0"
                allow="autoplay; encrypted-media"
                allowFullScreen
              ></iframe> */}
              <iframe
                className="embed-responsive-item"
                src="https://www.youtube.com/embed/uqozP61jgKQ"
                title="CODETRAIN TECH CAREER SEMINAR, 2023"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
          </div>
          <div className="col-md-4">
            <div className="row">
              <div className="col">
                <h3 className="main-section-heading text-center">
                  From the ground up
                </h3>
                <p>
                  No prior experience in tech is required. If you're willing to
                  learn, We're happy to teach you.
                </p>

                <p>
                  Robert, Confidence and some other alumni share about their
                  Codetrain experience in this video.
                </p>
              </div>
            </div>
            <div className="row">
              <Link
                to="https://app.codetrain.africa/apply"
                className="btn-custom-primary-large enroll-section mx-auto"
              >
                Enroll now
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WhyDifferent
