import React from "react"
import { Link } from "gatsby"
import "./homeHero.scss"

import students from "../../assets/img/hero/graduants.jpg"

const content = {
  title: "Start your career in Software Engineering ",
  description:
    "Become a fullstack web and mobile apps developer, get practical training and projects experience, get connected to job opportunities",
  button: "Enroll now",
  btnClass: "btn-custom-primary-large",
  url: "https://app.codetrain.africa/apply",
  image: students,
}

const HomeHero = () => {
  return (
    <section className="home-hero-section">
      <div
        className="slider-wrapper"
        style={{
          background: `url('${content.image}') no-repeat center center`,
        }}
      >
        <div className="slider-content">
          <div className="inner">
            <h3>{content.title}</h3>
            <h5>or UX/UI design.</h5>
            <br />
            <ul className="hero-list">
              <li>
                <p className="list-items">
                  Learn in-demand tech skills that companies are hiring for
                </p>
              </li>
              <li>
                <p className="list-items">
                  Get practical experience by working on projects and building a
                  portfolio
                </p>
              </li>
              <li>
                <p className="list-items">
                  Get connected to jobs and start earning
                </p>
              </li>
            </ul>

            <br />

            <Link to={content.url} className={content.btnClass}>
              {content.button}
            </Link>

            {/* <Link to="/courses" style={{ color: "#fff" }}>
              [View our Course]
            </Link> */}

            <p className="hero-announcement">
              * Admission is in progress for the May class. Register Now!
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default HomeHero
